import { Controller } from '@hotwired/stimulus'
import { Helpers } from 'pistachio'

export default class extends Controller {
  static targets = [
    'form',
    'calculation',
  ]

  connect() {
    this.update()
  }

  update() {
    this.refreshPrice()
  }

  refreshPrice() {
    const url = this.data.get('url')
    console.log(url)
    const params = Helpers.getFormDataForXHR(this.formTarget)

    Helpers.ajax({
      url: url,
      type: 'POST',
      data: params,
      success: (response, status, xhr) => {
        this.calculationTarget.innerHTML = xhr.responseText
      }
    })
  }
}
